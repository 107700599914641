import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, inject } from "@angular/core";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  DateFilter,
  MultipleFilter,
  MultipleFilterData,
} from "@src/app/models/Tegett/SimpleFilterData";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods, FiltersData } from "@src/app/models/base/filterModel";
import { ConfiguracionConceptosService } from "../services/configuracionConceptos.service";
import { ConfiguracionConceptosSettings } from "./configuracionConceptos.settings";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-configuracionConceptosSearch",
  template: "",
})
export class ConfiguracionConceptosSearch implements BaseSearchSetting {

  tiposConceptos: MultipleFilter;
  conceptos: MultipleFilter;
  selectionPopup = false;


  private configuracionConceptosServices = inject(ConfiguracionConceptosService);
  private router = inject(Router)
  private route = inject(ActivatedRoute)
  private settings = inject(ConfiguracionConceptosSettings)

  constructor() { }



  async Load() {
    this.tiposConceptos = await this.ObtenerTipoConcepto();
    this.conceptos = this.ObtenerTipoConfiguracion();
    if (this.tiposConceptos) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
  * Funcion para armar la busqueda
  */
  loadFormSearch(): FormSearch {
    const search = new FormSearch();

    search.formComponents = this.settings.FormComponents()
    search.configSearch = {
      title: "Configuración Conceptos",
      titleIcon: "fa-book-sparkles fa-regular",
      apiSearch: ControllerApiList.Nomina.ConfiguracionConceptos,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nueva Configuración Conceptos",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [
        { objectKey: "id", sortV3: "asc", visible: false },
      ],
      fields: [
        {
          renderLabel: "Identificador Concepto",
          objectKey: "llave",
          module: "nomina",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          selectionPopup: this.selectionPopup,
        },
        {
          objectKey: "tipoConcepto",
          renderLabel: "Tipo Concepto",
          render: (value) => `
          <div  class="t-row">
             <i class="text-sm fa-solid ${value == 'Devengados'
              ? 'text-green-600 fa-plus' : value == 'Deducciones'
              ? 'text-red-600 fa-minus' : value == 'Variable'
              ? 'text-blue-400 fa-diamond' : '' }"
              ></i>
            <div>${value}</div>
          </div>`,
        },
        {
          objectKey: "tipoNovedad",
          renderLabel: "Grupo Concepto",
        },
        {
          objectKey: "calculo",
          renderLabel: "Calculo",
        },
        {
          objectKey: "tipoCalculo",
          renderLabel: "Tipo Calculo",
          render: (value) => `

        <div class="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full
           ${value == 'constante' ? 'text-blue-700 bg-blue-100 border border-blue-300':
             value == 'formula' ? ' text-green-700 bg-green-100 border border-green-300':
             value == 'calculoInterno' ? ' text-purple-700 bg-purple-100 border border-purple-300': ''}  ">
            <div class="text-xs font-normal leading-none max-w-full flex-initial">${
               value == 'constante' ? 'Valor':
               value == 'formula' ? 'Fórmula':
               value == 'calculoInterno' ? 'Cálculo Interno': ''

            }</div>
        </div>

          `,
        },
      ],
      filters: [
        { objectKey: "descripcion", type: SearchTypeFilter.Text },
        { objectKey: "llave", type: SearchTypeFilter.Text },
        {
          objectKey: "idTipoConcepto",
          type: SearchTypeFilter.MultipleFilter,
          noMap: true,
          data: this.tiposConceptos,
        },
        {
          objectKey: "idConcepto",
          type: SearchTypeFilter.MultipleFilter,
          noMap: true,
          data: this.conceptos,
        }

      ],
    };
    return search;
  }


  async ObtenerTipoConcepto() {

    let filter = {} as MultipleFilter;
    filter.data = [];
    filter.label = "Tipos Conceptos";
    filter.type = SearchTypeFilter.MultipleFilter;

    let b = this.configuracionConceptosServices.ObteneridTipoConcepto<any[]>();
    const dataResponse = await lastValueFrom(b);
    if (dataResponse) {
      dataResponse.forEach((elemt, index) => {
        let filterValue = {} as MultipleFilterData;
        filterValue.field = index;
        filterValue.text = elemt.nombre;
        filterValue.value = elemt.id;
        filter.data.push(filterValue);
      });
      return filter;
    }
    return null;
  }
  ObtenerTipoConfiguracion() {

    let filter = {} as MultipleFilter;
    filter.data = [];
    filter.label = "Conceptos";
    filter.type = SearchTypeFilter.MultipleFilter;
    filter.data = [
      {
        field: 1,
        text: "Sistema",
        value: 1,
        htmlBefore: undefined,
        name: undefined,

      },
      {
        field: 2,
        text: "Empleados",
        value: 2,
        htmlBefore: undefined,
        name: undefined,

      }
    ];


    return filter;
  }


  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }


  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
