import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ProcessType, SPlusDialogSettings, SPlusFormsDialogsService, SPlusFormsService } from '@saludplus/forms';
import { ComponentsCoreFormsModule, DirectivesCoreFormsModule } from '@src/app/components/Core/core.saludplus.module';
import { SearchModule } from '@src/app/core/Search/search.module';
import { FiltersData } from '@src/app/models/base/filterModel';
import { Empleados } from '@src/app/models/Nomina/Empleados.model';
import { EmpleadosSearch } from '@src/app/pages/SaludPlus/Nomina/empleados/config/empleados.search';
import { EmpleadosSettings } from '@src/app/pages/SaludPlus/Nomina/empleados/config/empleados.settings';
import { EmpleadosService } from '@src/app/pages/SaludPlus/Nomina/empleados/service/empleados.service';


@Component({
  selector: 'lookup-empleados',
  templateUrl: './lookupEmpleados.component.html',
  styleUrls: ['./lookupEmpleados.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchModule,
    ComponentsCoreFormsModule, DirectivesCoreFormsModule,
    //kendo
    DropDownsModule, PopupModule, InputsModule

  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LookupEmpleadosComponent,
      multi: true
    },
    EmpleadosSettings, EmpleadosSearch
  ]

})
export class LookupEmpleadosComponent implements ControlValueAccessor {

  control = new FormControl();
  @Input() splusGroup: any;
  @Input() id: string;
  @Input() valuePrimitive: boolean = true;
  @Output() selectionChange = new EventEmitter<Empleados | number>();
  viewModeValue = "";
  data: Empleados[] = [];
  loading = false;
  private sPlusFormsDialogsService = inject(SPlusFormsDialogsService);
  private empleadosService = inject(EmpleadosService);

  setting = EmpleadosSearch;

  closePop: boolean;
  inPopUp: boolean;

  @Input() processType: ProcessType;
  ProcessType = ProcessType;

  filters: FiltersData[] = [];
  constructor() {

    this.filters = [
      {
        propertyName: "selectionPopup",
        propertyValue: true,
      }
    ];
  }
  public async open(): Promise<void> {
    if (this.data.length == 0) {
      this.loading = true;
      this.data = await this.empleadosService.CargarEmpleadosAsync<Empleados[]>(null, false, 5);
      this.loading = false;
    }
  }
  async writeValue(value: number | Empleados): Promise<void> {
    if (value) {
      const idSearch = typeof value === 'number' ? value : value.id;
      if (!this.data.find(item => item.id === idSearch)) {
        const userSearchParams = {
          idEmpleado: value.toString()
        };

        const dataApi = await this.empleadosService.BuscarAsync<Empleados>(userSearchParams);
        if (dataApi) {
          dataApi.nombre = dataApi?.nombreCompleto;
          this.viewModeValue = dataApi.numeroDocumento + " - " + dataApi?.nombreCompleto;
          this.data.push(dataApi);
        }

      }
    }
    this.control.setValue(value, { emitEvent: true });

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  onChange = (_: Empleados | number) => { };
  private onTouched = () => { };

  selectionChangeEmpleado(value: Empleados | number): void {
    this.onChange(value);
    this.control.setValue(value); // Actualiza el FormControl
    this.selectionChange.emit(value); // Emite el valor seleccionado

  }


  ngOnInit() {
  }

  async Filtrar(value: string, IsViewMode = false) {
    if (!value || value.length < 3) {
      return null;
    }

    const isSelection = this.data.some(item =>
      item.nombre?.toLowerCase() == value.toLowerCase() ||
      item.numeroDocumento === value
    );

    if (isSelection) {
      return null;
    }

    try {
      this.loading = true;
      this.data = await this.empleadosService.CargarEmpleadosAsync<Empleados[]>(value, IsViewMode);
      this.loading = false;

    } catch (error) {
      console.error("Error al cargar empleados:", error);
      return null;
    }
  }



  @ViewChild("container", { read: ViewContainerRef }) public containerRef: ViewContainerRef;
  OpenDiagBuscar(template: TemplateRef<unknown>): void {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.content = template;
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.width = "70%";
    dialogSettings.title = "Listado Empleados";
    dialogSettings.actions = [{ text: "Cerrar" }]
    dialogSettings.actionsLayout = "end"
    this.sPlusFormsDialogsService.ShowCustom(dialogSettings)
  }

  GetSelection(seleccion: Empleados) {

    this.data = [seleccion];
    this.viewModeValue = seleccion?.numeroDocumento + " - " + seleccion?.nombreCompleto
    if (this.valuePrimitive){
      this.control.setValue(seleccion.id);
      this.onChange(seleccion.id)
      this.selectionChange.emit(seleccion.id);
    }
    else{
      this.control.setValue(seleccion);
      this.onChange(seleccion);
      this.selectionChange.emit(seleccion);
    }

    //this.onChange(seleccion.id);


    this.closePop = true;
    setTimeout(() => {
      this.closePop = false;
    }, 500)
  }

}
