import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ControllerApiList } from "@app/models/SaludPlus/Controller/ControllerList";
import { SaludPlusApiResponse } from "@src/app/models/base/SaludplusApiResponse";
import { lastValueFrom, map, Observable } from "rxjs";
import { SplusBaseService } from "@src/app/services/Generals/splusBase.service";


@Injectable({
  providedIn: "root",
})
export class ConfiguracionConceptosService extends SplusBaseService {
  static instance: ConfiguracionConceptosService;
  error: HttpErrorResponse;

  constructor() {
    super();
    ConfiguracionConceptosService.instance = this;
    this.nameEndPoint = `${ControllerApiList.Nomina.ConfiguracionConceptos}`
  }



  /**
  * Obtener obtener idTipoConcepto
  * @returns Observable<SaludPlusApiResponse<T>>
  */
  ObteneridTipoConcepto<T>(): Observable<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.ConfiguracionConceptos}/TiposConceptos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;
  }
 

  /**
  * Obtener obteneridTipoNovedad
  * @returns Observable<SaludPlusApiResponse<T>>
  */
  ObteneridTipoNovedad<T>(): Observable<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.ConfiguracionConceptos}/TipoNovedades`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;
  }

  ConceptosPredefinidos<T>(): Observable<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.ConfiguracionConceptos}/ConceptosPredefinidos`
    ).pipe(map(response => {
      console.log(response);
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;
  }


  async CargarFormulasAsync <T>(): Promise<T> {
    
    const fromObject =
    {
      "pageSize": 100,
      "pageNumber": 1, 
    }

    let params = new HttpParams({ fromObject: fromObject });
    let request = this.http.get<SaludPlusApiResponse<T>>(`${ControllerApiList.Nomina.ConfiguracionConceptos}/Listado`, {
      params: params,
    }).pipe(map((s) => s.result));;

    let dataMap = await lastValueFrom(request);
    return dataMap;
  }

  async CargarEmpleados<T>(search: string): Promise<T> {
    var prop = "segundoApellido,primerApellido,segundoNombre,primerNombre,numeroDocumento";


    const fromObject =
    {
      "pageSize": 50,
      "pageNumber": 1,
      "properties": prop,
      "filter": search,
      "sort": "id",
    }

    let params = new HttpParams({ fromObject: fromObject });
    let request = this.http.get<SaludPlusApiResponse<T>>(`${ControllerApiList.Nomina.Empleados}/Listado`, {
      params: params,
    }).pipe(map((s) => s.result));;

    let dataMap = await lastValueFrom(request);
    return dataMap;
  }

}
