<kendo-combobox  splusInput  [formControl]="control"
[loading]="loading"
[splusGroup]="splusGroup" [filterable]="true" [data]="data" valueField="id"
(open)="open()" (valueChange)="selectionChangeEmpleado($event)"
textField="nombre" [allowCustom]="true" placeholder="Escribe mas de tres caracteres para buscar..."
[id]="id" (filterChange)="Filtrar($event)" [valuePrimitive]="valuePrimitive">
<ng-template kendoDropDownListItemTemplate let-dataItem>
  <span>{{ dataItem?.tipoDocumento + " - " + dataItem?.numeroDocumento + " - " + dataItem?.nombre}}</span>
</ng-template>
<ng-template kendoDropDownListNoDataTemplate>
  <div>
    <i class="fa-sharp fa-regular fa-circle-exclamation"></i><br />
    No hay datos.<br />
  </div>
</ng-template>

<ng-template kendoPrefixTemplate [showSeparator]="true">
  <i title="empleado"
    class="pl-1 pr-1 fa-duotone fa-solid fa-user-tie-hair opacity-80 text-orange-500 text-base dark:text-orange-400 fa-lg"></i>
</ng-template>

<ng-template kendoSuffixTemplate [showSeparator]="true"
  *ngIf="processType !== ProcessType.view && !inPopUp">
  <button title="Buscar empleado" kendoButton type="button" style="padding: 0px 5px 0px 5px;"
    fillMode="flat" (click)="OpenDiagBuscar(popupBuscar)"> <i
      class="fa-duotone fa-arrow-up-right-from-square text-primary text-base dark:text-blue-500"></i>
  </button>
</ng-template>

</kendo-combobox>


<ng-template #popupBuscar class="fuente">
  <app-search-in-list [inPopup]="true" (SendDataEmitter)="GetSelection($event)" [setting]="setting"
    [filtersData]="filters">
  </app-search-in-list>
</ng-template>
<ng-container #container *ngIf="!closePop"></ng-container>
