import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import Validation, { rangoFechasValidator } from "@src/app/components/Core/directives/generals/validatorError";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { ConfiguracionConceptosService } from "../services/configuracionConceptos.service";
import { CustomValidators } from "@src/app/Components/Core/directives/generals/CustomValidators";

export class ConfiguracionConceptosSettings {

  private configuracionConceptosServices = inject(ConfiguracionConceptosService);

  constructor() { }

  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {

        descripcion: new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Descripción",
          typeField: TypeField.Input,
          funtionData: undefined,

          nameControl: "descripcion",

        }),

        llave: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required,CustomValidators.ToVariableName],
          label: "Identificador Concepto",
          typeField: TypeField.Input,
          required: true,
          nameControl: "llave",

        }),

       formulaSeleted: new TegettFormControl({
          formState: "valor",
          validatorOrOpts: [],
          typeField: TypeField.Input,
          nameControl: "formulaSeleted",

        }),

        idTipoConcepto: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required],
          asyncValidator: undefined,
          label: "Tipo Concepto",
          typeField: TypeField.List,
          funtionData: this.configuracionConceptosServices.ObteneridTipoConcepto(),
          required: true,
          nameControl: "idTipoConcepto",
          dataTextField: 'nombre',
          dataValueField: 'id',

        }),

        idTipoNovedad: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required],
          asyncValidator: undefined,
          label: "Grupo Concepto",
          typeField: TypeField.List,
          funtionData: this.configuracionConceptosServices.ObteneridTipoNovedad(),
          required: true,
          nameControl: "idTipoNovedad",
          dataTextField: 'nombre',
          dataValueField: 'id',

        }),

        constante: new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Valor",
          typeField: TypeField.Input,
          funtionData: undefined,
          required: false,
          nameControl: "constante",

        }),

        formula: new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Fórmula",
          typeField: TypeField.Input,
          nameControl: "formula",

        }),

        idQuery: new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Cálculo Interno",
          typeField: TypeField.Input,
          funtionData: undefined,
          required: false,
          nameControl: "idQuery",
        }),

        predefinido : new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Concepto Predefinido",
          typeField: TypeField.List,
          funtionData: this.configuracionConceptosServices.ConceptosPredefinidos(),
          required: false,
          nameControl: "predefinido",
          dataTextField: 'nombre',
          dataValueField: 'nombre',

        }),


        preseleccionado: new TegettFormControl({
          formState: false,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Preseleccionar en liquidación",
          typeField: TypeField.CheckBox,
          funtionData: undefined,
          required: false,
          nameControl: "preseleccionado",

        }),

        editable: new TegettFormControl({
          formState: false,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Editable",
          typeField: TypeField.CheckBox,
          funtionData: undefined,
          required: false,
          nameControl: "editable",

        }),

        aplicaTercero: new TegettFormControl({
          formState: false,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Aplica Tercero",
          typeField: TypeField.CheckBox,
          funtionData: undefined,
          required: false,
          nameControl: "aplicaTercero",

        }),

        idTerceroDefault: new TegettFormControl({
          formState: null,
          validatorOrOpts: [],
          label: "Tercero Predeterminado",
          typeField: TypeField.Input,
          required: false,
          nameControl: "idTerceroDefault",
        }),

        aplicaEmpleado: new TegettFormControl({
          formState: false,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Aplica Empleado",
          typeField: TypeField.CheckBox,
          funtionData: undefined,
          required: false,
          nameControl: "aplicaEmpleado",

        }),


        aplicaCantidad: new TegettFormControl({
          formState: false,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Aplica Cantidad",
          typeField: TypeField.CheckBox,
          funtionData: undefined,
          required: false,
          nameControl: "aplicaCantidad",

        }),


        porcentajeDeduccion: new TegettFormControl({
          formState: null,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Porcentaje de deducción trabajador",
          typeField: TypeField.Input,
          nameControl: "porcentajeDeduccion"
        }),

        idEmpleado: new TegettFormControl({
          formState: null,
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Empleado",
          typeField: TypeField.List,
          nameControl: "idEmpleado"
        }),

        fechaInicio: new TegettFormControl({
          label: "Fecha Inicio concepto",
          typeField: TypeField.Date,
          nameControl: "fechaInicio",
        }),

        fechaFin: new TegettFormControl({
          label: "Fecha final concepto",
          typeField: TypeField.Date,
          nameControl: "fechaFin",
        }),
      },{validators:rangoFechasValidator('fechaInicio', 'fechaFin')}
    );
    return formRecord;
  }


  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
