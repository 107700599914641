<!-- formula-autocomplete.component.html -->
<div class="relative">
    <kendo-textarea #formulaInput [formControl]="formulaControl" [id]="id" [splusGroup]="splusGroup"
        (valueChange)="onChange($event)" (input)="onInput($event)" (keypress)="onKeyPress($event)"  class="internal-ctrl whitespace-nowrap" 
        [rows]="3" wrap="off">
    
        <kendo-textarea-suffix class="disabled-suffix">


             <span class="k-flex-1"></span>         
            <button kendoButton fillMode="flat" type="button" kendoPopoverAnchor [popover]="myPopover">
              <i class="fa-duotone fa-solid fa-square-question text-primary dark:text-blue-500 "></i>
            </button>
          </kendo-textarea-suffix>
    </kendo-textarea>



    <kendo-popup [anchor]="formulaInput.input" *ngIf="formulaInput.value && filteredOptions.length > 0">
        <ul #optionsList class="w-96 max-h-60 overflow-y-auto border border-gray-300 rounded bg-white shadow-md">
            <li *ngFor="let option of filteredOptions; let i = index"
                class="p-2 cursor-pointer flex items-center justify-between hover:bg-gray-100"
                [class.bg-gray-200]="i === selectedIndex" (click)="selectOption(option)"
                (mouseover)="showOptionTooltip($event, option)" (mouseout)="hideTooltip()">
                <div class="flex items-center">
                    <i class="{{ getIconForKind(option.kind) }} w-5 text-center mr-2"></i>
                    <span>{{ option.label }}</span>
                    <!-- <span class="ml-2 text-gray-500">({{ option.kind }})</span>-->
                </div>
                <span class="text-gray-500">{{ option.detail }}</span>
            </li>
        </ul>
    </kendo-popup>

    <kendo-popup *ngIf="showTooltip" [offset]="tooltipPosition">
        <div class="bg-gray-100 border border-gray-300 p-2 rounded text-sm shadow-md" >
            {{ tooltipContent }}
        </div>
    </kendo-popup>
</div>

<kendo-popover #myPopover [width]="450" position="right">
    <ng-template kendoPopoverTitleTemplate>
      <span>
        <div class="mb-2 text-center">
          <h2 class="text-base font-bold text-gray-800 dark:text-white">
            <i class="fa-duotone fa-solid fa-circle-info text-blue-500 dark:text-blue-400 mr-1"></i>
            Guía de Fórmulas
          </h2>
        </div>
      </span>
    </ng-template>

    <ng-template kendoPopoverBodyTemplate>
      <div
      class="flex-none   ">
      <div class="max-w-xl p-1 text-gray-900 dark:text-white">

        
          <div class="space-y-6">
          
            <div class="bg-green-50 dark:bg-green-900 p-4 rounded-md">
              <div class="flex items-center mb-3">
                <i
                  class="fa-duotone fa-solid fa-square-root-variable text-green-600 dark:text-green-400 text-xl mr-2"></i>
                <h3 class="text-lg font-semibold text-green-800 dark:text-green-200">Fórmula</h3>
              </div>
              <ul class="list-disc list-inside text-sm text-gray-700 dark:text-gray-300 space-y-2">
                <li>Se pueden usar las operaciones matemáticas básicas</li>
                <li>La fórmula es de tipo texto</li>
                <li>Se pueden valores y otras fórmulas</li>
                <li>Se puede usar la coma ',' para separar decimales</li>
              </ul>
              <div class="text-sm mt-2">Ejemplos:</div>
              <div class="bg-white dark:bg-gray-700 p-3 rounded mt-2 text-sm font-mono"> 
                <div class="border-b border-gray-200 dark:border-gray-600 py-2">UnValor * 100</div>
                <div class="border-b border-gray-200 dark:border-gray-600 py-2">(UnValor + 100) / 2</div>
                <div class="py-2">((OtraFormula + 0,50) + UnValor) / 100</div>
              </div>
            </div>
           
        </div>
      </div>
    </div>
    </ng-template>

 
  </kendo-popover>