<splus-forms id="Configuracion ConceptosPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">

    <splus-forms-header [isLoading]="isLoading">

      <splus-forms-main-title [title]="title" [icon]="icon"> </splus-forms-main-title>


      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading"
            *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton rounded="small"
            themeColor="primary">
            <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
            rounded="small" themeColor="primary" (click)="goEdit()">
            <i class="fa-solid fa-pencil"></i> Editar
          </button>

          <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small" themeColor="base"
            (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small" themeColor="base"
            (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>


    </splus-forms-header>

    <splus-forms-fields [processType]="processType">

      <splus-forms-group [title]="'Información Básica'" #separadorInformacionBasica>


        <splus-field *ngVar="f.llave as ctrl" [splusFormControl]="ctrl">
          <kendo-textbox splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl">

            <ng-template kendoTextBoxPrefixTemplate>
              <div class="m-1">
                <i class="fa-duotone fa-solid fa-lock text-primary dark:text-blue-500"></i>
              </div>
              <kendo-textbox-separator></kendo-textbox-separator>
            </ng-template>

          </kendo-textbox>
        </splus-field>

        <splus-field *ngVar="f.idTipoConcepto as ctrl" [splusFormControl]="ctrl">
          <div class="flex">
            <kendo-dropdownlist #select splusInput [splusGroup]="separadorInformacionBasica" [formControl]="ctrl"
              [data]="ctrl?.funtionData | async" textField="nombre" valueField="id" [valuePrimitive]="true"
              (valueChange)="idTipoConceptoChange()">

              <ng-template kendoDropDownListItemTemplate let-dataItem>

                <i [ngClass]="{'text-green-600 fa-plus' : dataItem.id == 1, 'text-red-600 fa-minus' : dataItem.id == 2, 'text-blue-500 fa-diamond' : dataItem.id == 3}"
                  class="fa-solid    "></i>

                {{ dataItem.nombre }}


              </ng-template>

            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.idTipoNovedad as ctrl" [splusFormControl]="ctrl">
          <div class="flex">
            <kendo-dropdownlist #select splusInput [splusGroup]="separadorInformacionBasica" [formControl]="ctrl"
              [data]="ctrl?.funtionData | async" textField="nombre" valueField="id" [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.descripcion as ctrl" [splusFormControl]="ctrl">
          <kendo-textarea splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl">

          </kendo-textarea>
        </splus-field>



      </splus-forms-group>

      <splus-forms-group [title]="'Información de deducción'" #separadorInformacionPorcentaje>

        <splus-field *ngVar="f.porcentajeDeduccion as ctrl" [splusFormControl]="ctrl">
          <kendo-numerictextbox style="width: 120px !important;" max="100" min="0" format="##.#" splusInput
            [splusGroup]="separadorInformacionPorcentaje" type="text" [id]="ctrl.nameControl" [formControl]="ctrl"
            [step]="10">


            <ng-template kendoTextBoxPrefixTemplate>
              <div class="m-1">
                <i class="fa-duotone fa-solid fa-lock text-primary dark:text-blue-500"></i>
              </div>
              <kendo-textbox-separator></kendo-textbox-separator>
            </ng-template>

            <ng-template kendoSuffixTemplate>
              <button kendoButton fillMode="clear">%</button>
            </ng-template>

          </kendo-numerictextbox>
        </splus-field>


      </splus-forms-group>

      <div class="flex flex-col md:flex-row">
        <div class="flex-auto min-w-60">
          <splus-forms-group [title]="'Información Fórmulas'" #separadorInformacionFormula
            [mainLayout]="'layout-simple'">
            <div class="flex flex-col space-y-4">


              @if (f.formulaSeleted.value != 'query') {
              <div class="flex items-start ">


                <splus-field class="ml-2 flex items-center mr-3" *ngVar="f.formulaSeleted as ctrl"
                  [splusFormControl]="ctrl" [viewModeDisable]="true" labelCustom="">
                  <input #ctrlRadio type="radio" value="valor" (change)="checkedChange('valor')" rounded="small"
                    splusInput kendoRadioButton [formControl]="ctrl" [splusGroup]="separadorInformacionFormula"
                    [id]="ctrl.nameControl" />

                  <ng-template splusFieldViewModeTemplate>
                    <splus-radiobutton-info [checked]="ctrlRadio?.checked">
                    </splus-radiobutton-info>
                  </ng-template>

                  <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
                    class="select-none text-slate-500 dark:text-slate-400 text-sm">
                    {{ctrl?.label}}</label>
                </splus-field>




                <div class="w-[250px]">
                  <splus-field *ngVar="f.constante as ctrl" [splusFormControl]="ctrl"
                    specialIcon="fa-duotone fa-solid fa-infinity text-blue-600 dark:text-blue-400">
                    <kendo-numerictextbox splusInput [splusGroup]="separadorInformacionFormula" [id]="ctrl.nameControl"
                      [spinners]="false" format="n2" [formControl]="ctrl">

                      <ng-template kendoSuffixTemplate>
                        <kendo-textbox-separator></kendo-textbox-separator>
                        <button kendoButton fillMode="flat" type="button" kendoPopoverAnchor [popover]="myPopover">
                          <i class="fa-duotone fa-solid fa-square-question text-primary dark:text-blue-500 "></i>
                        </button>
                      </ng-template>

                    </kendo-numerictextbox>
                  </splus-field>
                </div>
              </div>

              <div class="flex items-start  ">


                <splus-field class="ml-2 flex items-center mr-3" *ngVar="f.formulaSeleted as ctrl"
                  [splusFormControl]="ctrl" [viewModeDisable]="true" labelCustom="">
                  <input class="mt-4" #ctrlRadio type="radio" value="formula" (change)="checkedChange('formula')"
                    rounded="small" splusInput kendoRadioButton [formControl]="ctrl"
                    [splusGroup]="separadorInformacionFormula" [id]="ctrl.nameControl" />

                  <ng-template splusFieldViewModeTemplate>
                    <splus-radiobutton-info [checked]="ctrlRadio?.checked">
                    </splus-radiobutton-info>
                  </ng-template>

                  <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
                    class="select-none text-slate-500 dark:text-slate-400 text-sm">
                    {{ctrl?.label}}</label>
                </splus-field>

                <div class="w-[450px]">
                  <splus-field *ngVar="f.formula as ctrl" [splusFormControl]="ctrl"
                    specialIcon="fa-duotone fa-solid fa-square-root-variable text-green-600 dark:text-green-400">
                    <app-formula-autocomplete splusInput [splusGroup]="separadorInformacionFormula"
                      [id]="ctrl.nameControl" [formControl]="ctrl" [IdentificadorConcepto]="f.llave.value"
                      [processType]="processType == ProcessType.view">
                    </app-formula-autocomplete>
                  </splus-field>
                </div>
              </div>




              <div class="flex items-start  ">


                <splus-field class="ml-2 flex items-center mr-3" *ngVar="f.formulaSeleted as ctrl"
                  [splusFormControl]="ctrl" [viewModeDisable]="true" labelCustom="">
                  <input class="mt-4" #ctrlRadio type="radio" value="predefinido" (change)="checkedChange('predefinido')"
                    rounded="small" splusInput kendoRadioButton [formControl]="ctrl"
                    [splusGroup]="separadorInformacionFormula" [id]="ctrl.nameControl" />

                  <ng-template splusFieldViewModeTemplate>
                    <splus-radiobutton-info [checked]="ctrlRadio?.checked">
                    </splus-radiobutton-info>
                  </ng-template>

                  <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
                    class="select-none text-slate-500 dark:text-slate-400 text-sm">
                    {{ctrl?.label}}</label>
                </splus-field>

                <div class="w-[450px]">
                  <splus-field *ngVar="f.predefinido as ctrl" [splusFormControl]="ctrl">
                    <div class="flex">
                      <kendo-dropdownlist #select splusInput [splusGroup]="separadorInformacionBasica" [formControl]="ctrl"
                        [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">

                        <ng-template kendoSuffixTemplate>
                          <kendo-textbox-separator></kendo-textbox-separator>
                          <button kendoButton fillMode="flat" type="button" kendoPopoverAnchor [popover]="myPopover">
                            <i class="fa-duotone fa-solid fa-square-question text-primary dark:text-blue-500 "></i>
                          </button>
                        </ng-template>

                      </kendo-dropdownlist>
                    </div>
                    <ng-template splusFieldViewModeTemplate>
                      {{select["text"]}}
                    </ng-template>
                  </splus-field>
          
          
                </div>
              </div>




              }

              @if (f.formulaSeleted.value == 'query') {



              <div class="flex-grow w-[450px]">
                <div class="flex justify-start">
                  <span class="splus-form-label text-slate-500 dark:text-slate-400 flex flex-row">
                    <div class="flex flex-row">Cálculo Interno
                    </div>
                  </span>
                </div>

                <kendo-textbox readonly="true" type="text"
                  placeholder="Este cálculo es interno y administrado por el sistema">
                  <ng-template kendoTextBoxPrefixTemplate>
                    <i class="pl-1 pr-1 fa-duotone fa-solid fa-lock-keyhole text-primary dark:text-blue-500"></i>
                    <kendo-textbox-separator></kendo-textbox-separator>

                  </ng-template>


                  <ng-template kendoSuffixTemplate>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton fillMode="flat" type="button" kendoPopoverAnchor [popover]="myPopover">
                      <i class="fa-duotone fa-solid fa-square-question text-primary dark:text-blue-500 "></i>
                    </button>
                  </ng-template>

                </kendo-textbox>
              </div>

              }

            </div>

          </splus-forms-group>
        </div>


      </div>

      <splus-forms-group [title]="'Opciones Fórmulas'" #InformacionOpcionesFormulas>


        
        <splus-field class="ml-2 flex items-center mt-2" *ngVar="f.preseleccionado as ctrl" [splusFormControl]="ctrl"
          [viewModeDisable]="true" labelCustom="">
          <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
            [formControl]="ctrl" [splusGroup]="InformacionOpcionesFormulas" [id]="ctrl.nameControl" />

          <ng-template splusFieldViewModeTemplate>
            <splus-checkbox-info [checked]="ctrlCheck?.checked">
            </splus-checkbox-info>
          </ng-template>

          <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
            class="select-none text-slate-500 dark:text-slate-400 text-sm">
            {{ctrl?.label}}</label>
        </splus-field>
        


        <splus-field class="ml-2 flex items-center mt-2" *ngVar="f.aplicaCantidad as ctrl" [splusFormControl]="ctrl"
        [viewModeDisable]="true" labelCustom="">
        <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
          [formControl]="ctrl" [splusGroup]="InformacionOpcionesFormulas" [id]="ctrl.nameControl" />

        <ng-template splusFieldViewModeTemplate>
          <splus-checkbox-info [checked]="ctrlCheck?.checked">
          </splus-checkbox-info>
        </ng-template>

        <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
          class="select-none text-slate-500 dark:text-slate-400 text-sm">
          {{ctrl?.label}}</label>
      </splus-field>


        <splus-field class="ml-2 flex items-center mt-2" *ngVar="f.editable as ctrl" [splusFormControl]="ctrl"
        [viewModeDisable]="true" labelCustom="">
        <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
          [formControl]="ctrl" [splusGroup]="InformacionOpcionesFormulas" [id]="ctrl.nameControl" />

        <ng-template splusFieldViewModeTemplate>
          <splus-checkbox-info [checked]="ctrlCheck?.checked">
          </splus-checkbox-info>
        </ng-template>

        <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
          class="select-none text-slate-500 dark:text-slate-400 text-sm">
          {{ctrl?.label}}</label>
      </splus-field>

      

      </splus-forms-group>


      <splus-forms-group [title]="'Información Empleado'" #InformacionEmpleado>


        <splus-field class="ml-2 flex items-center mt-4" *ngVar="f.aplicaEmpleado as ctrl" [splusFormControl]="ctrl"
          [viewModeDisable]="true" labelCustom="">
          <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
            [formControl]="ctrl" [splusGroup]="InformacionEmpleado" [id]="ctrl.nameControl" />

          <ng-template splusFieldViewModeTemplate>
            <splus-checkbox-info [checked]="ctrlCheck?.checked">
            </splus-checkbox-info>
          </ng-template>

          <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
            class="select-none text-slate-500 dark:text-slate-400 text-sm">
            {{ctrl?.label}}</label>
        </splus-field>

        <splus-field *ngVar="f.idEmpleado as ctrl" [splusFormControl]="ctrl">

          <lookup-empleados #select splusInput [splusGroup]="InformacionEmpleado" [id]="ctrl.nameControl"
            [formControl]="ctrl"></lookup-empleados>

          <ng-template splusFieldViewModeTemplate>
            {{select["viewModeValue"]}}
          </ng-template>

        </splus-field>


        <splus-field *ngVar="f.fechaInicio as ctrl" [splusFormControl]="ctrl" #fecha>
          <kendo-datepicker #calendar splusInput [navigation]="true" format="dd/MM/yyyy" [formControl]="ctrl"
            [splusField]="fecha" [value]="ctrl.value" [splusGroup]="InformacionEmpleado">
            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{ isNaN(title) ? title : " Ene. " + title.substring(2, 4) }}
            </ng-template>
          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{ calendar.value | date:'dd/MM/yyyy' }}
          </ng-template>
        </splus-field>
        <splus-field *ngVar="f.fechaFin as ctrl" [splusFormControl]="ctrl" #fecha>
          <kendo-datepicker #calendar splusInput [navigation]="true" [min]="minFinalDate" format="dd/MM/yyyy"
            [formControl]="ctrl" [splusField]="fecha" [value]="ctrl.value" [splusGroup]="InformacionEmpleado">
            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{ isNaN(title) ? title : " Ene. " + title.substring(2, 4) }}
            </ng-template>
          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{ calendar.value | date:'dd/MM/yyyy' }}
          </ng-template>
        </splus-field>

      </splus-forms-group>

      <splus-forms-group [title]="'Información Terceros'" #InformacionTerceros>

        <splus-field class="ml-2 flex items-center mt-4" *ngVar="f.aplicaTercero as ctrl" [splusFormControl]="ctrl"
          [viewModeDisable]="true" labelCustom="">
          <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
            [formControl]="ctrl" [splusGroup]="InformacionTerceros" [id]="ctrl.nameControl" />

          <ng-template splusFieldViewModeTemplate>
            <splus-checkbox-info [checked]="ctrlCheck?.checked">
            </splus-checkbox-info>
          </ng-template>

          <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''"
            class="select-none text-slate-500 dark:text-slate-400 text-sm">
            {{ctrl?.label}}</label>
        </splus-field>


        <splus-field *ngVar="f.idTerceroDefault as ctrl" [splusFormControl]="ctrl">

          <lookup-terceros #select splusInput [splusGroup]="InformacionTerceros" [id]="ctrl.nameControl"
            [formControl]="ctrl"></lookup-terceros>

          <ng-template splusFieldViewModeTemplate>
            {{select["viewModeValue"]}}
          </ng-template>

        </splus-field>


      </splus-forms-group>

    </splus-forms-fields>

  </form>

  <kendo-popover #myPopover [width]="450" position="right">
    <ng-template kendoPopoverTitleTemplate>
      <span>
        <div class="mb-2 text-center">
          <h2 class="text-base font-bold text-gray-800 dark:text-white">
            <i class="fa-duotone fa-solid fa-circle-info text-blue-500 dark:text-blue-400 mr-1"></i>
            Guía de Fórmulas
          </h2>
        </div>
      </span>
    </ng-template>

    <ng-template kendoPopoverBodyTemplate>
      <div class="flex-none   ">
        <div class="max-w-xl p-1 text-gray-900 dark:text-white">


          <div class="space-y-6">
            @if(f.formulaSeleted.value == 'valor'){
            <div class="bg-blue-50 dark:bg-blue-900 p-4 rounded-md">
              <div class="flex items-center mb-3">
                <i class="fa-duotone fa-solid fa-infinity text-blue-600 dark:text-blue-400 text-xl mr-2"></i>
                <h3 class="text-lg font-semibold text-blue-800 dark:text-blue-200">Valor</h3>
              </div>
              <ul class="list-disc list-inside text-sm text-gray-700 dark:text-gray-300 space-y-2">
                <li>Especifica un valor para el cálculo</li>
                <li>Los valores son de tipo numérico</li>
                <li>Se puede usar la coma ',' para separar decimales</li>
              </ul>
              <div class="text-sm mt-2">Ejemplos:</div>
              <div class="bg-white dark:bg-gray-700 p-3 rounded mt-2 text-sm font-mono">
                <div class="border-b border-gray-200 dark:border-gray-600 py-2">5000</div>
                <div class="border-b border-gray-200 dark:border-gray-600 py-2">1500,35</div>
              </div>
            </div>
            }

            @if(f.formulaSeleted.value == 'formula'){
            <div class="bg-green-50 dark:bg-green-900 p-4 rounded-md">
              <div class="flex items-center mb-3">
                <i
                  class="fa-duotone fa-solid fa-square-root-variable text-green-600 dark:text-green-400 text-xl mr-2"></i>
                <h3 class="text-lg font-semibold text-green-800 dark:text-green-200">Fórmula</h3>
              </div>
              <ul class="list-disc list-inside text-sm text-gray-700 dark:text-gray-300 space-y-2">
                <li>Se pueden usar las operaciones matemáticas básicas</li>
                <li>La fórmula es de tipo texto</li>
                <li>Se pueden valores y otras fórmulas</li>
                <li>Se puede usar la coma ',' para separar decimales</li>
              </ul>
              <div class="text-sm mt-2">Ejemplos:</div>
              <div class="bg-white dark:bg-gray-700 p-3 rounded mt-2 text-sm font-mono">
                <div class="border-b border-gray-200 dark:border-gray-600 py-2">UnValor * 100</div>
                <div class="border-b border-gray-200 dark:border-gray-600 py-2">(UnValor + 100) / 2</div>
                <div class="py-2">((OtraFormula + 0,50) + UnValor) / 100</div>
              </div>
            </div>
            }

            @if(f.formulaSeleted.value == 'query'){
            <div class="bg-purple-50 dark:bg-purple-900 p-4 rounded-md">
              <div class="flex items-center mb-3">
                <i class="fa-duotone fa-solid fa-arrow-progress text-purple-600 dark:text-purple-400 text-xl mr-2"></i>
                <h3 class="text-lg font-semibold text-purple-800 dark:text-purple-200">Cálculo Interno</h3>
              </div>
              <ul class="list-disc list-inside text-sm text-gray-700 dark:text-gray-300 space-y-2">
                <li>Son las fórmulas complejas y únicas que se ejecutan en el sistema</li>
                <li>Este tipo de fórmulas NO se puede editar por los usuarios.</li>
                <li>Se pueden usar para crear nuevas fórmulas</li>
                <li>Se pueden usar en la liquidación de nóminas</li>
              </ul>
            </div>
            }

          </div>
        </div>
      </div>
    </ng-template>


  </kendo-popover>

</splus-forms>


<ng-container #container *ngIf="!closePop"></ng-container>